'use client'
import carLogo from '@/app/[locale]/Lezgo-car-logo.png';
import Image from 'next/image'
import { Button } from "./ui/button";
import { useRouter } from "@/i18n/routing"
import { sendGTMEvent } from "@next/third-parties/google";

export default function HomeButton() {
  const router = useRouter()

  return (
    <Button variant={"outline"} onClick={() => {
      sendGTMEvent("homeButtonClicked");
      router.push("/")
    }}>
      <div className="flex gap-1">
        <Image
          src={carLogo}
          alt="Car logo"
          sizes="100vw"
          style={{
            width: '24px',
            height: 'auto',
          }}
        />
        <div>Lezgo</div>
      </div>
    </Button>
  );
}